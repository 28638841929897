import axios from "axios";
import store from "../store";

export const interceptorsSetup = () => {
  axios.interceptors.request.use(
    config => {
      let userData = JSON.parse(localStorage.getItem("userData"));
      if (!config.url.includes("/login/access-token")) {
        config.headers["Accept"] = "application/json";
        if (
          userData &&
          userData["access_token"] &&
          !config.url.includes("/file")
        ) {
          config.headers[
            "Authorization"
          ] = `Bearer ${userData["access_token"]}`;
        }
        if (config.url.includes("/login/refresh-token")) {
          delete config.headers['Authorization']
        }
      } else {
        config.headers["Content-Type"] = "multipart/form-data";
      }
      return Promise.resolve(config);
    },
    error => {
      return Promise.reject(error);
    }
  );
};
let isRefreshing = false;
let subscribers = [];
function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onRefresh() {
  subscribers.map(callFunction => callFunction());
}
export function interceptorsResponse() {
  axios.interceptors.response.use(
    function(response) {
      return Promise.resolve(response);
    },
    function(error) {
      const { status, data: { detail: { token } = {} } = {} } = error.response || {};
      const { config } = error;
      const originalRequest = config;
      if (originalRequest.url.includes("/login/refresh-token")) {
        return Promise.reject(error)
      }
      if (status === 403) {
        if (!isRefreshing) {
          isRefreshing = true;
          store
            .dispatch("refreshToken")
            .then(({ status }) => {
              if (status === 200 || status == 204) {
                onRefresh();
              }
            })
            .catch(refreshErr => {
               const { response : {status} = {} } = refreshErr || {};
               if(status === 403){
                store.dispatch("logout")
               }
            }).finally(()=> {
              isRefreshing = false;
            });
        }
        const requestSubscribers = new Promise(resolve => {
          subscribeTokenRefresh(() => {
            resolve(axios(originalRequest));
          });
        });
        return requestSubscribers;
      }
      if (status === 401 && token) {
        store.dispatch("redirectToReset", token);
        return;
      }
      if (status === 419) {
        store.dispatch("logout")
        store.dispatch("clearAllToast")
        store.dispatch("showToast", {
          class: "bg-danger text-white",
          message: 'You have been logged out, as a new login session for the same credentials has been detected!',
          autohide: 7000
        })
        return;
      }
      return Promise.reject(error);
    }
  );


}
